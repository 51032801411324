<template>
  <ion-page>
    <div class="content">
      <iframe id="bx-iframe-dedicatii" src="https://app.distractieonline.ro/dedicatii-mesaje.php" style="margin:0; border:none; overflow:hidden;" width="100%" height="100%"></iframe>
    </div>
  </ion-page>
</template>

<script>
import {IonPage} from '@ionic/vue';

export default {
  components: {
    IonPage
  },
};
</script>

<style scoped>
.ion-page {
  background-color: #3880ff;
}

.content {
  height: 100%;
}
</style>